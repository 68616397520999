import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';

import MetaTags from '../../../components/MetaTags';

import { theme } from '../../../styles/theme';

import Background from '../../../components/Background';
import Header from '../../../components/Header2';
import HowGroupGiftsWork from '../../../sections/occasion/HowGroupGiftsWork';

import CuratedGifts from '../../../sections/occasion/CuratedGifts';

import GroupCard from '../../../sections/occasion/GroupCard';
import LoveIllume from '../../../sections/occasion/LoveIllume';
import JoinOccasions from '../../../sections/JoinOccasions';
import Footer from '../../../sections/common/FooterWithNav';
import futureCelebrating from '../../../images/occasion/birthday-friend.png';
import futurePhone from '../../../images/occasion/iPhone12.png';

import data from '../../../sections/occasion/data';
import HeaderSection from '../../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionBirthdayFriend: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Show you care with group birthday ecards and gifts from illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Group Birthday Gifts for Friends'}
          paragraphs={[
            'Tim’s birthday is two days away and your trivia team almost forgot… But it’s not over yet! There’s still time to send a group gift with illume.',
            'Pick a fun gift or gift card from illume’s digital marketplace, invite the team to contribute with cash, messages, photos, GIFs, and more – and then hit send before the big day. Tim will never see it coming until you hit his inbox with that sweet ray of sunshine :)',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=4bbdfa7c-9759-4e85-a5b7-4df328d9feb7&email=email@illumenotes.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Birthday for a Friend',
            },
            phone: {
              image: futurePhone,
              label: 'Birthday for a Friend Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of celebrating birthdays'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionBirthdayFriend;
